.content {
  min-height: calc(100vh - 45px);
  margin: 0;
  overflow: auto;
}

.section {
  overflow: hidden;
}

.collapsed {
  padding-left: 80px;
}

.expanded {
  padding-left: 255px;
}
