@import '~antd/dist/antd.css';
@import './custom-ant.scss';

* {
  padding: 0px;
  margin: 0px;
  font-family: 'Inter', sans-serif;
}

.mb-10px {
  margin-bottom: 10px;
}
.mb-1 {
  margin-bottom: 4px;
}

.mt-1 {
  margin-top: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-4 {
  margin-left: 16px;
}

.bold {
  font-weight: bold;
}
