.formWrapper {
  input {
    width: 90%;
  }

  textarea {
    right: 0.5%;
    width: 96.5%;
    height: 200px;
  }

  display: flex;
  flex-direction: column;
  padding: 11px;
  justify-content: flex-end;

  .people {
    margin-top: -45px !important;
  }

  :global {
    .view_message {
      margin-bottom: 10px;
      max-height: 500px;
      overflow-y: scroll;
      // font-size: 16px;
    }

    .msgRightWrapper {
      // display: flex;
      margin-top: 5;
      flex-direction: 'row';
      justify-content: flex-end;
      margin-right: 16;
    }

    .msgLeftWrapper {
      // display: flex;
      margin-top: 5;
      flex-direction: 'row';
      justify-content: 'flex-start';
      margin-right: 16;
    }

    .Form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      margin-top: 16px;
      border: 1px solid rgb(230, 230, 230);
      border-radius: 15px;

      .ant-form-item {
        flex: 1;
        margin-bottom: 0;
      }

      // của phần button chat
      .button-chat {
        display: flex;
        justify-content: space-between;
      }

      .ant-upload-picture-card-wrapper {
        display: inline-block;
        width: 200%;
      }

      .ant-upload.ant-upload-select.ant-upload-select-picture-card {
        width: 61px;
        height: 33px;
      }
    }

    .content {
      // margin-left: 1.2%;
      max-width: 50%;
    }

    span.ant-typography.author {
      font-weight: bold;
    }

    span.ant-typography.date {
      margin-left: 0.3%;
      font-size: 11px;
      color: #a7a7a7;
    }

    span.ant-typography.date-sender {
      margin-right: 0.1%;
      margin-left: 10px;
      font-size: 11px;
      color: #a7a7a7;
    }

    span.ant-typography.author-sender {
      margin-right: 1.5%;
      font-weight: bold;
    }

    .content-sender {
      text-align: right;
      margin-right: 1.5%;
      margin-left: 50%;
    }

    .img-sender {
      display: flex;
      justify-content: flex-end;
      margin-right: 1.5%;
    }

    .event-sender {
      display: flex;
      justify-content: flex-end;
      margin-right: 1.5%;
    }

    .event-content {
      line-height: 1.5em;
      height: 3em;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
      font-size: 17px;
      overflow: hidden;
    }

    .title-form-sender {
      display: flex;
      justify-content: end;
    }

    .ant-card.ant-card-bordered.event-card {
      width: 400px;
      height: 170px;
      border: ridge;
    }

    .ant-select {
      width: 90% !important;
    }

    .ant-divider-horizontal {
      margin: 18px 0;
    }

    .ant-form-item-label > label {
      color: rgb(117, 117, 117);
    }

    .ck.ck-editor {
      height: 100%;
      width: 100%;
    }

    .ck-content {
      height: 400px;
    }

    .ant-checkbox-inner {
      padding: 10px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #2ebcb1;
      border-color: #2ebcb1;
    }

    .ant-row.ant-form-item.text {
      display: flex !important;
      flex-direction: column !important;
    }

    .text > .ant-col.ant-col-24.ant-form-item-control {
      flex-direction: column-reverse !important;
    }

    .ant-input-number-group-wrapper {
      width: 120px !important;
    }

    .input-number2 {
      margin-left: 33.33% !important;
      display: flex !important;
      justify-content: space-between !important;
      max-width: 60% !important;
    }

    .input-number1 {
      display: flex !important;
      justify-content: space-between !important;
      max-width: 90% !important;
    }

    .ant-list-split .ant-list-item {
      border: none !important;
      padding: 0;
    }

    .ant-list-empty-text {
      display: none;
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;

    button {
      min-width: 100px;
      cursor: pointer;
    }
  }
}

:global {
  .tox-notification.tox-notification--in.tox-notification--warning {
    display: none;
  }
}
