.formWrapper {
  input {
    width: 90%;
  }

  textarea {
    right: 0.5%;
    width: 96.5%;
    height: 200px;
  }

  .people {
    margin-top: -45px !important;
  }

  :global {

    .ant-input-number.ant-input-number-in-form-item {
      width: 90%;
    }

    .processingTime_History {
      display: flex;
    }

    .ant-form-item.processingTime {
      width: 57%;
    }

    .horizontal {
      display: flex;

      .ant-form-item.input-horizontal {
        padding-left: 8px;

        .ant-row.ant-form-item-row {
          width: 762%;
        }
      }
    }

    .ant-select {
      width: 90% !important;
    }

    .ant-divider-horizontal {
      margin: 18px 0;
    }

    .ant-form-item-label>label {
      color: rgb(117, 117, 117);
    }

    .ant-checkbox-inner {
      padding: 10px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #2ebcb1;
      border-color: #2ebcb1;
    }

    .ant-row.ant-form-item.text {
      display: flex !important;
      flex-direction: column !important;
    }

    .text>.ant-col.ant-col-24.ant-form-item-control {
      flex-direction: column-reverse !important;
    }

    .ant-input-number-group-wrapper {
      width: 120px !important;
    }

    .input-number2 {
      margin-left: 33.33% !important;
      display: flex !important;
      justify-content: space-between !important;
      max-width: 60% !important;
    }

    .input-number1 {
      display: flex !important;
      justify-content: space-between !important;
      max-width: 90% !important;
    }

    .ant-list-split .ant-list-item {
      border: none !important;
      padding: 0;
    }

    .ant-list-empty-text {
      display: none;
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;

    button {
      min-width: 100px;
      cursor: pointer;
    }
  }
}

:global {
  .tox-notification.tox-notification--in.tox-notification--warning {
    display: none;
  }
}