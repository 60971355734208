.wrapInfo {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 11px;
  line-height: 14px;
  .content {
    flex: 1 1 0%;
    padding: 0px 10px;
    .textProfileName {
      margin: 0;
      color: '#111111';
      font-size: 14px;
    }
  }
}

.arrowIcon {
  transition: all 0.25s ease-in-out;
  align-items: center;
  justify-content: center;
  height: 12px;
  margin-top: 2px;
  display: flex;
}

.dropDown {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 5px;
}
.textModal {
  margin-left: 10px;
}

.active {
  transform: rotate(180deg);
}

.textGrey {
  color: #767676;
}
