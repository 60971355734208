.wrapper {
  margin: 24px;
  background-color: #fff;
  padding: 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  :global {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: black;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.formWrapper {
  input {
    width: 90%;
  }

  .placeholder {
    width: 90%;
  }
  :global {
    .ant-select.ant-select-in-form-item {
      width: 90%;
    }
  }
}

.wrapperButton {
  display: flex;
  justify-content: flex-end;
}

:global {
  input.ant-input.note_input {
    width: 80.6%;
  }
}
