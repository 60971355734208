.slider {
  border-right: 1px solid #f0f0f0;
  height: 100vh;
  position: fixed !important;
  overflow: auto;
  left: 0;
  top: 0;
  bottom: 0;

  .logo {
    background-color: white;
    display: flex;
    justify-content: center;

    .logoWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  :global {
    .ant-layout-sider-children {
      background-color: #fff;
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: 0px;
    }
  }
}

.bold {
  font-weight: 600;
}

.sidebar {
  display: flex;
  justify-content: center;

  :global {
    .ant-menu-item-selected a {
      color: #2ebcb1;
    }
    .ant-menu-item a:hover {
      color: #2ebcb1;
    }

    .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
      color: #2ebcb1;
    }

    .ant-menu-item-selected {
      color: #2ebcb1;
      border-radius: 5px;
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
      padding-left: 14px !important;
      .ant-menu-title-content {
        font-weight: 600;
      }
    }

    .ant-menu-sub.ant-menu-inline>.ant-menu-item,
    .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
      padding-left: 28px !important;
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
      border-right: none;
    }

    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-title:hover {
      color: #2ebcb1;
    }

    .ant-menu-title-content {
      font-size: 14px;
      font-weight: 500;
    }
  }
}